@import '../hover.scss';

.Button {
	padding: 1em 2em;
	text-transform: uppercase;
	letter-spacing: .03em;
	&.Yellow {
		border: $yellow 2px solid;
		border-radius: 50px;
		color: $yellow;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			background: $yellow;
			color: $blue;
		}
	}
	&.Blue {
		color: $yellow;
		background: $dblue;
		transition: all .3s ease;
		border-radius: 50px;
		padding:1em 4em;
		&:hover {
			background: $yellow;
			color: $blue;
			cursor: pointer;
		}
	}
}