.Intro {
	padding: 3em 0 3.6em;
	.IntroGraphics {
		padding-top: 0em;
	}
	.IntroContent {
		padding: 0 1em;
		@media only screen and (min-width: 769px) {
			padding-top: 5em;
			padding-left: 3em;
		}
		h2 {
			font-size: 30px;
			color: $blue;
			line-height: 1.2;
		}
		p {
			line-height: 1.6;
			font-size: 1em;
			color: $dblue;
		}
		ul {
			color: $green;
			font-weight: bold;
			list-style: none;
			padding: 0;
			margin: 2em 0;
			li {
				margin-bottom: 1.5em;
				padding-left: 2em;
				background: url('../img/check.png') left center no-repeat;
			}
		}
	}
}