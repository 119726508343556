.Benefits {
	background: $dblue;
	padding: 3em 0 4em;
	.BenefitsContent {
		text-align: center;
		color: #fff;
		h2 {
			font-size: 30px;
			font-weight: normal;
			text-transform: uppercase;
		}
		hr {
			width: 100px;
			margin: 1.8em auto;
			border: none;
			position: relative;
			border-top: lighten($blue, 30%) 2px dashed;
			&:before {
				content:'';
				width:12px;
				height: 12px;
				border-radius: 50%;
				background: $yellow;
				border: lighten($blue, 30%) 2px solid;
				display: block;
				position: absolute;
				top:-8px; left: -6px;
			}
			&:after {
				content:'';
				width:12px;
				height: 12px;
				border-radius: 50%;
				background: $yellow;
				border: lighten($blue, 30%) 2px solid;
				display: block;
				position: absolute;
				top:-8px; right: -6px;
			}
		}
		p {
			max-width: 800px;
			line-height: 1.6;
			margin: 0 auto;
		}
	}
	.BenefitCards {
		padding: 2em 0 1em;
	}
	.BenefitCard {
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 3px 6px rgba(0,0,0,.3);
		padding: 2em 1em;
		text-align: center;
		margin: 1em 0;
		transition: all .3s ease-out;
		.Icon {
			img {
				width: 80px;
				height: auto;
				margin: 1em 0 1em;
				transition: all .36s ease-out;
				transform: scale(.92);
			}
			height: 130px;
		}
		h3 {
			color: $blue;
			margin: 0 0 .5em;
		}
		p {
			color: $dblue;
			margin: 0;
		}
		@media only screen and (min-width: 769px) {
			&:hover {
				transform: scale(1.1);
				.Icon {
					img {
						transform: scale(1);
					}
				}
			}
		}
	}
	.BenefitAction {
		text-align: center;
		margin: 1em 0 0;
	}
}

.FinalBenefit {
	padding: 0 1em 3em;
	text-align: center;
	@media (min-width:769px) {
		padding: 4em 0;
		text-align: left;
	}
	background: $blue;
	color: #fff;
	h3 {
		font-size: 30px;
		margin: 0 0 12px;
		font-weight: normal;
	}
	p {
		margin: 0;
		line-height: 1.6;
	}
	.ServerImage {
		text-align: center;
		img {
			max-width:150px;
			margin: 1em auto 1em;
		}
		@media only screen and (min-width: 769px) {
			img {
				height: auto;
				margin-top: -6em;
				margin-bottom: -2em;
			}
		}
		@media only screen and (min-width: 1025px) {
			img {
				height: auto;
				margin-top: -9em;
				margin-bottom: -1em;
			}
		}
	}
}