.FooterOne {
	background: transparent linear-gradient(240deg, #104F98 0%, #021D50 100%) 0% 0% no-repeat;
	text-align: center;
	@media only screen and (min-width: 769px) {
			text-align: left;
			}
		h1 {
			font-size: 36px;
			letter-spacing: 0;
			color: #FFFFFF;
			opacity: 1;
			font-weight: normal;
			text-transform: uppercase;
			padding-left: 8px;
			padding-top: 1em;
			margin: .5em 0;
			@media only screen and (min-width: 769px) {
				font-size: 42px;
			}
			@media only screen and (min-width: 1025px) {
				font-size: 50px
			}
		}
		h2 {
			margin: 1em 0;
			border: $yellow solid;
			border-width: 1px 0px;
			color: $yellow;
			text-transform: uppercase;
			font-size: 18px;
			padding: 1em 10px;
			font-weight: normal;
			@media only screen and (min-width: 769px) {
				font-size: 18px;
			}
			@media only screen and (min-width: 1025px) {
				font-size: 24px
			}
		}
		p {
			color: #fff;
			font-size: 1em;
			line-height: 1.6;
			padding: 0 2em 0 10px;
		}
		a.Button {
			display: inline-block;
			margin: 1em 10px 0;
		}
}

.FooterTwo {
	padding: 1em 0;
	background: darken($dblue, 5%);
	color: #fff;
	font-size: 12px;
	text-align: center;
	@media only screen and (min-width: 769px) {
	padding: 2em 0;
	text-align: left;
			}
	a {
		text-decoration: none;
		color: #fff;
		&:hover {
			color: $yellow;
		}
	}
	@media (min-width:769px) {
		.o {

		}
		.t {
			text-align: center;
		}
		.th {
			text-align: right;
		}
	}
}