header {
	background: transparent linear-gradient(240deg, #104F98 0%, #021D50 100%) 0% 0% no-repeat;
	opacity: 1;
	nav {
		display: flex;
		align-items: center;
		padding: 1.5em 0;
		.logo {
			flex-grow: 1;
			img {
				padding-left: 10px;
				max-width: 250px;
				margin:0 auto;
			}
			@media only screen and (min-width: 769px) {
			flex-shrink: 1;
				img {
					max-width: 280px;
				}
			}
		}
		.navigation {
			flex-grow: 1;
			display: none;
			@media only screen and (min-width: 769px) {
				display: block;
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: flex-end;
				li {
					a {
						color:#fff;
						text-decoration: none;
						padding: 0 1em;
						text-transform: uppercase;
						font-size: 14px;
						@media only screen and (min-width: 1025px) {
							padding: 0 1em;
							font-size: 1em;
						}
						&:hover {
							color: $yellow;
						}
					}
					&:last-child {
						a {
							padding-right: 0;
						}
					}
				}
			}
			@media only screen and (min-width: 1025px) {
				li.hasSubMenu {
					position: relative;
					&:hover {
						.SubMenu {
							opacity: 1;
							top: 100%;
							pointer-events: auto;
						}
					}
					.SubMenu {
						padding-top: 10px;
						box-sizing: border-box;
						position: absolute;
						opacity: 0;
						transition: all .3s ease;
						pointer-events: none;
						top: 60%; left: 1em;
						z-index: 1000;
						ul {
							flex-direction: column;
							width: 100px;
							background: #fff;
							border-radius: 4px;
							color: #000;
							li {
								a {
									display: block;
									padding: .5em;
									color:#000;
									&:hover {
										color: $dblue;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.HeaderContent {
		h1 {
			text-align: left;
			font-size: 36px;
			letter-spacing: 0;
			color: #FFFFFF;
			opacity: 1;
			font-weight: normal;
			text-transform: uppercase;
			padding-left: 8px;
			margin: .5em 0;
			@media only screen and (min-width: 769px) {
				font-size: 42px;
			}
			@media only screen and (min-width: 1025px) {
				font-size: 50px
			}
		}
		h2 {
			margin: 1em 0;
			border: $yellow solid;
			border-width: 1px 0px;
			color: $yellow;
			text-transform: uppercase;
			font-size: 18px;
			padding: 1em 10px;
			font-weight: normal;
			@media only screen and (min-width: 769px) {
				font-size: 18px;
			}
			@media only screen and (min-width: 1025px) {
				font-size: 24px
			}
		}
		p {
			color: #fff;
			font-size: 1em;
			line-height: 1.6;
			padding: 0 2em 0 10px;
		}
		a.Button {
			display: inline-block;
			margin: 1em 10px 2em;
		}
	}
	.HeaderImage {
		img {
			margin-bottom: -3em;
			margin-top: 2em;
		}
		@media only screen and (min-width: 769px) {
			img {
				margin-bottom: -6em;
			}
		}
	}
}
a.mnav {
	@media only screen and (min-width: 769px) {
		display: none;
	}
	display: block;
	width: 40px;
	height: 30px;
	justify-self: flex-end;
	span {
		display: block;
		height: 2px;
		background: #fff;
		width: 40px;
		margin-bottom: 10px;
	}
}

.mnavigation {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $dblue;
	top: -100%;
	left: 0; right: 0;
	z-index: 1001;
	transition: top .3s ease-out;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {

			a {
				color:#fff;
				text-decoration: none;
				padding: 1em;
				display: block;
				border-bottom: rgba(255,255,255,.2) thin solid;
				text-transform: uppercase;
				&:hover {
					color: $yellow;
				}
				img {
						max-width: 250px;
				}
			}
			&:last-child {
				a {
					padding-right: 0;
				}
			}
		}
	}
	.closebtn {
		position: absolute;
		bottom: 0px;
		right: 20px;
		display: block;
		left: 0; right: 0;
		height: 50px;
		color: #fff;
		font-size: 24px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		background: $blue;
		text-align: center;

	}
}

body.showMenu .mnavigation {
	top:0;
}

.BTT {
	position: fixed;
	bottom: 10px;
	right: 10px;
	background: $blue;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	color: #fff;
	text-decoration: none;
	border-radius: 50px;
	font-weight: bold;
	box-shadow: 0 3px 10px rgba(0,0,0,.1);
	opacity: 0;
	transition: opacity .3s ease;
}
body.showBTT {
	.BTT {
		opacity:1;
	}
}