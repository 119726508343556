.Form {
	padding: 2em 0;
	h2 {
		color: $blue;
		text-align: center;
		font-weight: normal;
		margin-bottom: 8px;
	}
	p {
		line-height: 1.6;
		font-size: 1em;
		text-align: center;
		max-width: 600px;
		margin: 0 auto 1em;
	}
	form {
		margin: 1em 0;
		label {
			display: block;
			margin-bottom: 8px;
			color: $blue;
			font-weight: bold;
			font-size: 14px;
		}
		input[type=text], select {
			width: 100%;
			-webkit-appearance: none;
			-moz-appearance: none;
			padding: 12px 8px;
			border-radius: 4px;
			border: #444 thin solid;
			background: #fff;
			@media only screen and (min-width: 769px) {
				border-color: #999;
			}
			margin-bottom: 1em;
			transition: all .3s ease;
			&:focus {
				border: $blue thin solid;
				box-shadow: 0 0 12px rgba($blue, .4);
				outline: none;
			}
		}
		select {
			background: url('../img/down.png') right center no-repeat;
		}
	}
}