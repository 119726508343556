@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import 'modules/normalize.scss';
@import 'modules/grid.scss';
@import 'modules/type.scss';
@import 'modules/header.scss';
@import 'modules/buttons.scss';
@import 'modules/Intro.scss';
@import 'modules/Benefits.scss';
@import 'modules/Form.scss';
@import 'modules/Footer.scss';

.nomobile {
	display: none;
	@media only screen and (min-width: 769px) {
		display: block;
	}
}

.mobile {
	display: block;
	@media only screen and (min-width: 769px) {
		display: none;
	}
}
